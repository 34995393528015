import { Box, Button, Container} from "@mui/material"
import { createParams, getShopInfo } from "./functions"
import { useEffect, useState } from "react"
import { isEmpty, isNil } from "lodash/fp"
import { FormsProps } from "../../types"

export const RegistCreditCard = (props: FormsProps) => {
    const { formData } = props
    const { memberId, companyName } = formData
    const [url, setUrl] = useState<string>("")
    const shopInfo = getShopInfo()
    const {
        domain,
        shopId
    } = shopInfo
    useEffect(() => {
        createParams(memberId, companyName).then((param) => {
            setUrl(`https://${domain}/v1/plus/${shopId}/member/${param}`)
        })
    }, [])

    const handleButtonClick = () => {
        if (!isNil(url)) {
            window.open(url, '_blank')
        }
    }

    return (
        <Box paddingTop={3}>
            <Container maxWidth="md">
                <Box>現在のお支払い方法はクレジット決済のみとなります。</Box>
                <Box>下記のボタンを押すと、決済代行会社の画面へ遷移します。お申込みには、お支払い情報の入力が必須となります。</Box>
                <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
                    <Button variant="contained" disabled={isEmpty(url)} onClick={handleButtonClick}>お支払い情報のご登録</Button>
                </Box>
            </Container>
        </Box>
    )
}