import { Person, CreditCardOutlined } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"


export const AdminMenu = () => {
    return (
        <Box display={"grid"} justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">管理者用ページ</Typography>
            <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3}>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><Person /> 従業員情報の確認</Typography>
                        <Button sx={{ marginY: 1 }} variant="contained" LinkComponent={"a"} href="/admin/employees">従業員情報管理</Button>
                    </Box>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><CreditCardOutlined />申込情報の確認</Typography>
                        <Button sx={{ marginY: 1 }} variant="contained" LinkComponent={"a"} href="/admin/requests"> 申込管理</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}