import { Box, Typography, Button } from "@mui/material"
import { CreditCardOutlined, ListAltOutlined, Person } from "@mui/icons-material"
import { useOutletContext } from "react-router-dom"
import { MypageOutletContext } from "../../types"

export const MyPageTop = () => {
    const { autoPayment } = useOutletContext<MypageOutletContext>()
    return (
        <Box display={"grid"} justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">ご利用者様マイページ</Typography>
            <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3}>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><Person /> 従業員情報（人数等）の新規登録、変更および会員ID・初期PW確認</Typography>
                        <Button sx={{ marginY: 1 }} variant="contained" LinkComponent={"a"} href="/mypage/employee">従業員情報</Button>
                    </Box>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><ListAltOutlined /> ご契約内容・ご利用明細の確認</Typography>
                        <Button sx={{ marginY: 1 }} variant="contained" LinkComponent={"a"} href="/mypage/invoice-history" disabled={autoPayment !== 'auto'}>ご利用内容</Button>
                    </Box>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><CreditCardOutlined />ご登録情報の変更</Typography>
                        <Button sx={{ marginY: 1 }} variant="contained" LinkComponent={"a"} href="/mypage/profile" disabled={autoPayment !== 'auto'}> ご登録情報</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}