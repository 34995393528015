import { Container, Box, Stack, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"
import { useRef, useState } from "react"
import { FormsProps } from "../../types"

export const RequestForm = (props: FormsProps) => {
    const {
        register,
        setters,
        formData,
        errors
    } = props

    const [
        setCompanyName,
        setDepartmentName,
        setPost,
        setFamilyNameKanji,
        setFamilyNameKana,
        setFirstNameKanji,
        setFirstNameKana,
        setIndustry,
        setPhoneNumber,
        setFaxNumber,
        setMailAddress,
        setZipCode,
        setPrefecture,
        setAddress1,
        setAddress2,
        setAddress3,
        setQuestionnair,
        setOptionalAnswer
    ] = setters


    const companyNameRef = useRef<HTMLInputElement>(null)
    const departmentNameRef = useRef<HTMLInputElement>(null)
    const postRef = useRef<HTMLInputElement>(null)
    const familyNameKanjiRef = useRef<HTMLInputElement>(null)
    const familyNameKanaRef = useRef<HTMLInputElement>(null)
    const firstNameKanjiRef = useRef<HTMLInputElement>(null)
    const firstNameKanaRef = useRef<HTMLInputElement>(null)
    const industryRef = useRef<HTMLInputElement>(null)
    const phoneNumberRef = useRef<HTMLInputElement>(null)
    const faxNumberRef = useRef<HTMLInputElement>(null)
    const mailAddressRef = useRef<HTMLInputElement>(null)
    const zipCodeRef = useRef<HTMLInputElement>(null)
    const prefectureRef = useRef<HTMLInputElement>(null)
    const address1Ref = useRef<HTMLInputElement>(null)
    const address2Ref = useRef<HTMLInputElement>(null)
    const address3Ref = useRef<HTMLInputElement>(null)
    const questionnairRef = useRef<HTMLInputElement>(null)
    const optionalAnswerRef = useRef<HTMLInputElement>(null)

    const {
        companyName,
        departmentName,
        post,
        familyNameKanji,
        familyNameKana,
        firstNameKanji,
        firstNameKana,
        industry,
        phoneNumber,
        faxNumber,
        mailAddress,
        zipCode,
        prefecture,
        address1,
        address2,
        address3,
        questionnair,
        optionalAnswer
    } = formData


    const [isRequiredOptionalAnswer, setIsRequiredOptionalAnswer] = useState<boolean>(questionnair === "other")

    const handleChangeCompanyName = () => {
        setCompanyName(companyNameRef.current?.value)
    }
    const handleChangeDepartmentName = () => {
        setDepartmentName(departmentNameRef.current?.value)
    }
    const handleChangePost = () => {
        setPost(postRef.current?.value)
    }
    const handleChangeFamilyNameKanji = () => {
        setFamilyNameKanji(familyNameKanjiRef.current?.value)
    }
    const handleChangeFamilyNameKana = () => {
        setFamilyNameKana(familyNameKanaRef.current?.value)
    }
    const handleChangeFirstNameKanji = () => {
        setFirstNameKanji(firstNameKanjiRef.current?.value)
    }
    const handleChangeFirstNameKana = () => {
        setFirstNameKana(firstNameKanaRef.current?.value)
    }
    const handleChangeIndustry = () => {
        setIndustry(industryRef.current?.value)
    }
    const handleChangePhoneNumber = () => {
        setPhoneNumber(phoneNumberRef.current?.value)
    }
    const handleChangeFaxNumber = () => {
        setFaxNumber(faxNumberRef.current?.value)
    }
    const handleChangeMailAddress = () => {
        setMailAddress(mailAddressRef.current?.value)
    }
    const handleChangeZipCode = () => {
        setZipCode(zipCodeRef.current?.value)
    }
    const handleChangePrefecture = () => {
        setPrefecture(prefectureRef.current?.value)
    }
    const handleChangeAddress1 = () => {
        setAddress1(address1Ref.current?.value)
    }
    const handleChangeAddress2 = () => {
        setAddress2(address2Ref.current?.value)
    }
    const handleChangeAddress3 = () => {
        setAddress3(address3Ref.current?.value)
    }
    const handleChangeQuestionnair = (event: any) => {
        setQuestionnair(event.target.value)
        if (event.target.value === "other") {
            setIsRequiredOptionalAnswer(true)
        } else {
            setIsRequiredOptionalAnswer(false)
        }
    }
    const handleChangeOptionalAnswer = () => {
        setOptionalAnswer(optionalAnswerRef.current?.value)
    }
    return (
        <Container maxWidth="sm" sx={{ pt: 5 }}>
            <Box component="form" sx={{ mt: 1 }}>
                <Stack spacing={3}>
                    <TextField id="plan" label="プラン名称" value={"福利厚生プラン"} variant="filled" disabled></TextField>
                    <TextField id="corse" label="コース名称" value={"ビジエネライトコース"} variant="filled" disabled></TextField>
                    <TextField id="company_name" label="会社名" defaultValue={companyName} required inputRef={companyNameRef} {...register("companyName")} error={"companyName" in errors} helperText={errors.companyName?.message as string} onChange={handleChangeCompanyName} ></TextField>
                    <TextField id="department_name" label="部署名" defaultValue={departmentName} required inputRef={departmentNameRef} {...register("departmentName")} error={"departmentName" in errors} helperText={errors.departmentName?.message as string} onChange={handleChangeDepartmentName}></TextField>
                    <TextField id="post" label="役職名" defaultValue={post} required inputRef={postRef} {...register("post")} error={"post" in errors} helperText={errors.post?.message as string} onChange={handleChangePost}></TextField>
                    <TextField id="family_name_kanji" label="担当者姓・漢字" defaultValue={familyNameKanji} required inputRef={familyNameKanjiRef} {...register("familyNameKanji")} error={"familyNameKanji" in errors} helperText={errors.familyNameKanji?.message as string} onChange={handleChangeFamilyNameKanji}></TextField>
                    <TextField id="first_name_kanji" label="担当者名・漢字" defaultValue={firstNameKanji} required inputRef={firstNameKanjiRef} {...register("firstNameKanji")} error={"firstNameKanji" in errors} helperText={errors.firstNameKanji?.message as string} onChange={handleChangeFirstNameKanji}></TextField>
                    <TextField id="family_name_kana" label="担当者姓・カナ" defaultValue={familyNameKana} required inputRef={familyNameKanaRef} {...register("familyNameKana")} error={"familyNameKana" in errors} helperText={errors.familyNameKana?.message as string} onChange={handleChangeFamilyNameKana}></TextField>
                    <TextField id="first_name_kana" label="担当者名・カナ" defaultValue={firstNameKana} required inputRef={firstNameKanaRef} {...register("firstNameKana")} error={"firstNameKana" in errors} helperText={errors.firstNameKana?.message as string} onChange={handleChangeFirstNameKana}></TextField>
                    <TextField id="industry" label="業種" defaultValue={industry} required inputRef={industryRef} {...register("industry")} error={"industry" in errors} helperText={errors.industry?.message as string} onChange={handleChangeIndustry}></TextField>
                    <TextField id="phone_number" label="連絡先電話番号(ハイフンなし)" defaultValue={phoneNumber} required inputRef={phoneNumberRef} {...register("phoneNumber")} error={"phoneNumber" in errors} helperText={errors.phoneNumber?.message as string} onChange={handleChangePhoneNumber}></TextField>
                    <TextField id="fax_number" label="Fax番号" defaultValue={faxNumber} inputRef={faxNumberRef} onChange={handleChangeFaxNumber}></TextField>
                    <TextField id="mail_address" label="メールアドレス" defaultValue={mailAddress} required inputRef={mailAddressRef} {...register("mailAddress")} error={"mailAddress" in errors} helperText={errors.mailAddress?.message as string} onChange={handleChangeMailAddress}></TextField>
                    <TextField id="zip_code" label="郵便番号(ハイフンなし)" defaultValue={zipCode} required inputRef={zipCodeRef} {...register("zipCode")} error={"zipCode" in errors} helperText={errors.zipCode?.message as string} onChange={handleChangeZipCode}></TextField>
                    <TextField id="prefecture" label="都道府県" defaultValue={prefecture} required inputRef={prefectureRef} {...register("prefecture")} error={"prefecture" in errors} helperText={errors.prefecture?.message as string} onChange={handleChangePrefecture}></TextField>
                    <TextField id="address_1" label="市区町村" defaultValue={address1} required inputRef={address1Ref} {...register("address1")} error={"address1" in errors} helperText={errors.address1?.message as string} onChange={handleChangeAddress1}></TextField>
                    <TextField id="address_2" label="番地" defaultValue={address2} required inputRef={address2Ref} {...register("address2")} error={"address2" in errors} helperText={errors.address2?.message as string} onChange={handleChangeAddress2}></TextField>
                    <TextField id="address_3" label="アパート・マンション名・号室" defaultValue={address3} inputRef={address3Ref} onChange={handleChangeAddress3}></TextField>

                    <FormControl>
                        <InputLabel id="questionnair" required >アンケート</InputLabel>
                        <Select labelId="questionnair" id="select" label="アンケート" defaultValue={questionnair} required inputRef={questionnairRef} {...register("questionnair")} onChange={handleChangeQuestionnair} error={"questionnair" in errors}>
                            <MenuItem value={"none"}></MenuItem>
                            <MenuItem value={"mzc_hp"}>中部電力ミライズコネクトホームページ</MenuItem>
                            <MenuItem value={"bizene_site"}>ビジエネサイト</MenuItem>
                            <MenuItem value={"bizene_mail"}>ビジエネメールマガジン</MenuItem>
                            <MenuItem value={"dm"}>ダイレクトメール</MenuItem>
                            <MenuItem value={"flyer"}>ポスティングチラシ</MenuItem>
                            <MenuItem value={"group_introduction"}>中部電力ミライズグループからの紹介</MenuItem>
                            <MenuItem value={"org_introduction"}>所属団体からの紹介</MenuItem>
                            <MenuItem value={"other"}>その他</MenuItem>
                        </Select>
                        <FormHelperText error> {errors.questionnair?.message as string}</FormHelperText>
                    </FormControl>
                    <TextField id="optional_answer" label="自由回答欄" defaultValue={optionalAnswer} inputRef={optionalAnswerRef} onChange={handleChangeOptionalAnswer} disabled={!isRequiredOptionalAnswer}></TextField>
                </Stack>
            </Box>
        </Container>
    )
}