import { ArrowBackIosNewRounded } from "@mui/icons-material"
import { Box, Stack, TextField, Button, Typography, AlertColor, Snackbar, Alert, AlertTitle, Container } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { changeCompany, ChangeCompanyData, getCompany } from "./functions"
import log from "loglevel"
import { isEmpty } from "lodash/fp"
import { useOutletContext } from "react-router-dom"
import { Auth0Context, useAuth0 } from "@auth0/auth0-react"
import alertPropaties from './alert-propaties.json'
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { inspect } from "./inspect"
import { Loading } from "../Loading"
import { MypageOutletContext } from "../../types"
log.setLevel((process.env['LOG_LEVEL'] as log.LogLevelDesc) ?? log.levels.DEBUG)

export const ChangeCompanyInfo = () => {
  const { user } = useAuth0(Auth0Context)
  const sub = user?.sub as string
  const { memberId } = useOutletContext<MypageOutletContext>()
  const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [companyName, setCompanyName] = useState<string>("")
  const [departmentName, setDepartmentName] = useState<string>("")
  const [post, setPost] = useState<string>("")
  const [familyNameKanji, setFamilyNameKanji] = useState<string>("")
  const [familyNameKana, setFamilyNameKana] = useState<string>("")
  const [firstNameKanji, setFirstNameKanji] = useState<string>("")
  const [firstNameKana, setFirstNameKana] = useState<string>("")
  const [industry, setIndustry] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [faxNumber, setFaxNumber] = useState<string>("")
  const [mailAddress, setMailAddress] = useState<string>("")
  const [zipCode, setZipCode] = useState<string>("")
  const [prefecture, setPrefecture] = useState<string>("")
  const [address1, setAddress1] = useState<string>("")
  const [address2, setAddress2] = useState<string>("")
  const [address3, setAddress3] = useState<string>("")

  const defineSchema = {
    companyName: z.string().min(1, { message: '会社名を入力してください' }),
    departmentName: z.string().min(1, { message: '部署名を入力してください' }),
    post: z.string().min(1, { message: '役職名を入力してください' }),
    familyNameKanji: z.string().min(1, { message: '姓を入力してください' }),
    firstNameKanji: z.string().min(1, { message: '名を入力してください' }),
    familyNameKana: z.string().min(1, { message: '姓を入力してください' }),
    firstNameKana: z.string().min(1, { message: '名を入力してください' }),
    industry: z.string().min(1, { message: '業種を入力してください' }),
    phoneNumber: z.string()
      .min(1, { message: '電話番号を入力してください' })
      .regex(/^[0-9]*$/, '半角数字のみで入力してください'),
    mailAddress: z.string()
      .min(1, { message: 'メールアドレスを入力してください' })
      .email({ message: '正しいメールアドレスを入力してください' }),
    zipCode: z
      .string()
      .min(7, { message: '正しい郵便番号(ハイフンなし)を入力してください' })
      .max(7, { message: '正しい郵便番号(ハイフンなし)を入力してください' })
      .regex(/^[0-9]{7}$/, '半角数字のみで入力してください'),
    prefecture: z.string().min(1, { message: '県を入力してください' }),
    address1: z.string().min(1, { message: '市区町村を入力してください' }),
    address2: z.string().min(1, { message: '番地以下を入力してください' }),
  }
  const schema = z.object(defineSchema)

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  })

  const onSubmit: SubmitHandler<any> = (_data) => {
    log.debug(`[handleSend]`)
    const sendData = {
      companyName,
      departmentName,
      post,
      familyNameKanji,
      familyNameKana,
      firstNameKanji,
      firstNameKana,
      industry,
      phoneNumber,
      faxNumber,
      mailAddress,
      zipCode,
      prefecture,
      address1,
      address2,
      address3
    }
    changeCompany(memberId, sub, sendData)
      .then((result: { success: boolean }) => {
        if (result.success) {
          setAlertStatus("success")
        } else {
          setAlertStatus("error")
        }
      }).catch(log.error)
  }

  const handleAlertClose = () => {
    setAlertStatus(undefined)
  }

  const companyNameRef = useRef<HTMLInputElement>(null)
  const departmentNameRef = useRef<HTMLInputElement>(null)
  const postRef = useRef<HTMLInputElement>(null)
  const familyNameKanjiRef = useRef<HTMLInputElement>(null)
  const familyNameKanaRef = useRef<HTMLInputElement>(null)
  const firstNameKanjiRef = useRef<HTMLInputElement>(null)
  const firstNameKanaRef = useRef<HTMLInputElement>(null)
  const industryRef = useRef<HTMLInputElement>(null)
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const faxNumberRef = useRef<HTMLInputElement>(null)
  const mailAddressRef = useRef<HTMLInputElement>(null)
  const zipCodeRef = useRef<HTMLInputElement>(null)
  const prefectureRef = useRef<HTMLInputElement>(null)
  const address1Ref = useRef<HTMLInputElement>(null)
  const address2Ref = useRef<HTMLInputElement>(null)
  const address3Ref = useRef<HTMLInputElement>(null)



  const handleChangeCompanyName = () => {
    setCompanyName(companyNameRef.current?.value ?? "")
  }
  const handleChangeDepartmentName = () => {
    setDepartmentName(departmentNameRef.current?.value ?? "")
  }
  const handleChangePost = () => {
    setPost(postRef.current?.value ?? "")
  }
  const handleChangeFamilyNameKanji = () => {
    setFamilyNameKanji(familyNameKanjiRef.current?.value ?? "")
  }
  const handleChangeFamilyNameKana = () => {
    setFamilyNameKana(familyNameKanaRef.current?.value ?? "")
  }
  const handleChangeFirstNameKanji = () => {
    setFirstNameKanji(firstNameKanaRef.current?.value ?? "")
  }
  const handleChangeFirstNameKana = () => {
    setFirstNameKana(firstNameKanjiRef.current?.value ?? "")
  }
  const handleChangeIndustry = () => {
    setIndustry(industryRef.current?.value ?? "")
  }
  const handleChangePhoneNumber = () => {
    setPhoneNumber(phoneNumberRef.current?.value ?? "")
  }
  const handleChangeFaxNumber = () => {
    setFaxNumber(faxNumberRef.current?.value ?? "")
  }
  const handleChangeMailAddress = () => {
    setMailAddress(mailAddressRef.current?.value ?? "")
  }
  const handleChangeZipCode = () => {
    setZipCode(zipCodeRef.current?.value ?? "")
  }
  const handleChangePrefecture = () => {
    setPrefecture(prefectureRef.current?.value ?? "")
  }
  const handleChangeAddress1 = () => {
    setAddress1(address1Ref.current?.value ?? "")
  }
  const handleChangeAddress2 = () => {
    setAddress2(address2Ref.current?.value ?? "")
  }
  const handleChangeAddress3 = () => {
    setAddress3(address3Ref.current?.value ?? "")
  }

  useEffect(() => {
    getCompany(memberId, sub).then((result: ChangeCompanyData) => {
      log.debug(`[index.getCompany]`, inspect({ result }))
      const {
        companyName,
        departmentName,
        post,
        familyNameKanji,
        familyNameKana,
        firstNameKanji,
        firstNameKana,
        industry,
        phoneNumber,
        faxNumber,
        mailAddress,
        zipCode,
        prefecture,
        address1,
        address2,
        address3
      } = result
      setCompanyName(companyName)
      setDepartmentName(departmentName)
      setPost(post)
      setFamilyNameKanji(familyNameKanji)
      setFamilyNameKana(familyNameKana)
      setFirstNameKanji(firstNameKanji)
      setFirstNameKana(firstNameKana)
      setIndustry(industry)
      setPhoneNumber(phoneNumber)
      setFaxNumber(faxNumber)
      setMailAddress(mailAddress)
      setZipCode(zipCode)
      setPrefecture(prefecture)
      setAddress1(address1)
      setAddress2(address2)
      setAddress3(address3)
      setIsLoading(false)
    }
    ).catch(log.error)
  }, [])



  return (
    <Box>
      {isLoading ? <Loading /> : <Container maxWidth="sm" sx={{ pt: 5 }}>
        <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">ご登録情報の変更</Typography>
        <Box component="form" sx={{ mt: 4 }}>
          <Stack spacing={3}>
            <TextField id="company_name" label="会社名" defaultValue={companyName} required inputRef={companyNameRef} {...register("companyName")} error={"companyName" in errors} helperText={errors.companyName?.message as string} onChange={handleChangeCompanyName}></TextField>
            <TextField id="department_name" label="部署名" defaultValue={departmentName} required inputRef={departmentNameRef} {...register("departmentName")} error={"departmentName" in errors} helperText={errors.departmentName?.message as string} onChange={handleChangeDepartmentName}></TextField>
            <TextField id="post" label="役職名" defaultValue={post} required inputRef={postRef} {...register("post")} error={"post" in errors} helperText={errors.post?.message as string} onChange={handleChangePost}></TextField>
            <TextField id="family_name_kanji" label="担当者姓・漢字" defaultValue={familyNameKanji} required inputRef={familyNameKanjiRef} {...register("familyNameKanji")} error={"familyNameKanji" in errors} helperText={errors.familyNameKanji?.message as string} onChange={handleChangeFamilyNameKanji}></TextField>
            <TextField id="first_name_kanji" label="担当者名・漢字" defaultValue={firstNameKanji} required inputRef={firstNameKanjiRef} {...register("firstNameKanji")} error={"firstNameKanji" in errors} helperText={errors.firstNameKanji?.message as string} onChange={handleChangeFirstNameKanji}></TextField>
            <TextField id="family_name_kana" label="担当者姓・カナ" defaultValue={familyNameKana} required inputRef={familyNameKanaRef} {...register("familyNameKana")} error={"familyNameKana" in errors} helperText={errors.familyNameKana?.message as string} onChange={handleChangeFamilyNameKana}></TextField>
            <TextField id="first_name_kana" label="担当者名・カナ" defaultValue={firstNameKana} required inputRef={firstNameKanaRef} {...register("firstNameKana")} error={"firstNameKana" in errors} helperText={errors.firstNameKana?.message as string} onChange={handleChangeFirstNameKana}></TextField>
            <TextField id="industry" label="業種" defaultValue={industry} required inputRef={industryRef} {...register("industry")} error={"industry" in errors} helperText={errors.industry?.message as string} onChange={handleChangeIndustry}></TextField>
            <TextField id="phone_number" label="連絡先電話番号" defaultValue={phoneNumber} required inputRef={phoneNumberRef} {...register("phoneNumber")} error={"phoneNumber" in errors} helperText={errors.phoneNumber?.message as string} onChange={handleChangePhoneNumber}></TextField>
            <TextField id="fax_number" label="Fax番号" defaultValue={faxNumber} inputRef={faxNumberRef} onChange={handleChangeFaxNumber}></TextField>
            <TextField id="mail_address" label="メールアドレス" defaultValue={mailAddress} required inputRef={mailAddressRef} {...register("mailAddress")} error={"mailAddress" in errors} helperText={errors.mailAddress?.message as string} onChange={handleChangeMailAddress}></TextField>
            <TextField id="zip_code" label="郵便番号" defaultValue={zipCode} inputRef={zipCodeRef} {...register("zipCode")} error={"zipCode" in errors} helperText={errors.zipCode?.message as string} onChange={handleChangeZipCode}></TextField>
            <TextField id="prefecture" label="都道府県" defaultValue={prefecture} required inputRef={prefectureRef} {...register("prefecture")} error={"prefecture" in errors} helperText={errors.prefecture?.message as string} onChange={handleChangePrefecture}></TextField>
            <TextField id="address_1" label="市区町村" defaultValue={address1} required inputRef={address1Ref} {...register("address1")} error={"address1" in errors} helperText={errors.address1?.message as string} onChange={handleChangeAddress1}></TextField>
            <TextField id="address_2" label="番地" defaultValue={address2} required inputRef={address2Ref} {...register("address2")} error={"address2" in errors} helperText={errors.address2?.message as string} onChange={handleChangeAddress2}></TextField>
            <TextField id="address_3" label="アパート・マンション名・号室" defaultValue={address3} inputRef={address3Ref} onChange={handleChangeAddress3}></TextField>
          </Stack>
        </Box>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={5}>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>送信</Button>
          <Button sx={{ marginTop: 5 }} variant="outlined" LinkComponent={"a"} href="/mypage/profile"><ArrowBackIosNewRounded />戻る</Button>
        </Box>
        <Snackbar open={!isEmpty(alertStatus)} autoHideDuration={5000} onClose={handleAlertClose}>
          <Alert severity={alertStatus}>
            <AlertTitle>{!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertTitle"] : ""}</AlertTitle>
            {!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertMessage"] : ""}
          </Alert>
        </Snackbar>
      </Container>
      }
    </Box>
  )
}