import * as React from "react"
import * as ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import Auth0Provider from './Auth0Provider'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material"
import { red } from "@mui/material/colors"

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

const theme = createTheme({
  palette: {
    primary: red
  }
})

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

