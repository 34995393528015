import axios from "axios"
import { isNil } from "lodash/fp"
import log from "loglevel"
import { createHash } from "./api"

const env = process.env['REACT_APP_ENV'] ?? 'dev'


const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
  }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
  }.bpo.${zoneName}`

export type InvoiceHistories = {
  id: string,
  amount: number
}

export type SubscriptionInfo = {
  plan: string,
  start: string
}

export const getInvoiceHistory = async (memberId: string, sub: string):
  Promise<
    Array<InvoiceHistories> | undefined> => {
  const subHash = await createHash('SHA-256', sub)
  const getInvoicesResult = await axios.get(`https://${domainName}/v1/users/invoices/${memberId}`,
    {
      headers: {
        Authorization: `${memberId}.${subHash}`
      }
    }
  )
  const invoices = getInvoicesResult.data
  if (isNil(getInvoicesResult.status !== 200)) {
    log.error(`[functions.getInvoiceHistory] Get failed.${memberId}`)
    return undefined
  }
  return invoices
}

export const getSubscription = async (memberId: string, sub: string):
  Promise<SubscriptionInfo | undefined> => {
  const subHash = await createHash('SHA-256', sub)
  const getSubscriptionResult = await axios.get(`https://${domainName}/v1/users/subscription/${memberId}`,
    {
      headers: {
        Authorization: `${memberId}.${subHash}`
      }
    }
  )
  const subscription = getSubscriptionResult.data
  if (isNil(getSubscriptionResult.status !== 200)) {
    log.error(`[functions.getSubscription] Get failed.${memberId}`)
    return undefined
  }
  return subscription
}
