import { Alert, AlertColor, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Snackbar, Stack, Typography } from "@mui/material"
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { confirmFile, doDownload, doUpload, getRows, unConfirm, xlsx2Json } from "./functions";
import { EmployeeDataColumns } from "./types";
import { isEmpty, isNil } from "lodash";
import log from "loglevel";
import { MuiFileInput } from "mui-file-input";
import { inspect } from "./inspect";
import alertPropaties from './alert-propaties.json'


export const AdminEmployees = () => {
  const [selectedRowId, setSelectedRowId] = useState<string>("")
  const [rows, setRows] = useState<EmployeeDataColumns[]>([])

  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [uploadFileIsExcelFile, setUploadFileIsExcelFile] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(undefined)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [isUnConfirming, setIsUnConfirming] = useState<boolean>(false)

  useEffect(() => {
    getRows().then((result) => {
      if (!isNil(result)) {
        setRows(result)
      }
    }).catch(log.error)
  }, [])

  const handleConfirmClick = async () => {
    setIsConfirming(true)
    if (!isEmpty(selectedRowId)) {
      const result = await confirmFile(selectedRowId)
      if (!isNil(result)) {
        getRows().then((rows) => {
          if (!isNil(rows)) {
            setRows(rows)
          }
        }).catch(log.error)
      }
    }
    setIsConfirming(false)
  }

  const handleDownloadClick = async () => {
    setIsDownloading(true)
    if (!isEmpty(selectedRowId)) {
      const row = rows.find((row) => row.id === selectedRowId)
      if (!isNil(row)) {
        await doDownload(selectedRowId, 'EmployeeRegistForm.xlsx', row.company)
      }
    }
    setIsDownloading(false)
  }

  const handleUnConfirmClick = async () => {
    setIsUnConfirming(true)
    if (!isEmpty(selectedRowId)) {
      const result = await unConfirm(selectedRowId)
      if (!isNil(result)) {
        getRows().then((rows) => {
          if (!isNil(rows)) {
            setRows(rows)
          }
        }).catch(log.error)
      }
    }
    setIsUnConfirming(false)
  }

  const handleUploadClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setUploadFile(null)
    setOpen(false)
  }

  const handleSubmit = async (uploadFile: File | null) => {
    if (!isNil(uploadFile)) {
      const row = rows.find((row) => row.id === selectedRowId) as EmployeeDataColumns
      const { company: companyName } = row
      const memberId = selectedRowId.split('/')[0]
      if (!isNil(memberId) && !isNil(companyName)) {
        const data = await xlsx2Json(uploadFile, "入力表")
        doUpload(memberId, data, companyName).then((result) => {
          if (!isNil(result)) {
            setAlertStatus("success")
            getRows().then((result) => {
              if (!isNil(result)) {
                setRows(result)
              }
            }).catch(log.error)
          } else {
            setAlertStatus("error")
          }
        }).catch((error) => {
          log.error(`[index.handleSubmit] Upload failed.`, inspect({ error }))
          setAlertStatus("error")
        })
      }
    } else {
      setAlertStatus("error")
    }
    handleClose()
  }

  const handleInput = (uploadFile: File | null) => {
    setUploadFile(uploadFile)
    if (!isNil(uploadFile) && (uploadFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      setUploadFileIsExcelFile(true)
    } else {
      setUploadFileIsExcelFile(false)
    }
  }
  const handleAlertClose = () => {
    setAlertStatus(undefined)
  }

  const columns: GridColDef[] = [
    { field: 'company', headerName: '会社名', width: 300 },
    { field: 'id', headerName: 'ファイル名', width: 500 },
    { field: 'uploadAt', headerName: 'アップロード日', width: 300 },
    { field: 'confirm', headerName: '承認', width: 200 },
    { field: 'closingDate', headerName: '締日', width: 200 }
  ]

  const paginationModel = { page: 0, pageSize: 50 }
  return (
    <Box>
      <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">従業員情報一覧</Typography>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel }, sorting: { sortModel: [{ field: 'uploadAt', sort: 'desc' }] } }}
          pageSizeOptions={[5, 10]}
          sx={{ border: 0 }}
          onRowClick={(event) => { setSelectedRowId(event.id.toString()) }}
        />
      </Paper>
      <Stack direction={"row"} spacing={3} justifyContent={"center"} marginTop={3}>
        <Button variant="contained" onClick={handleDownloadClick} disabled={isEmpty(selectedRowId) || isDownloading}>ダウンロード{isDownloading ? <CircularProgress size={20} color="inherit" /> : ""}</Button>
        <Button variant="contained" onClick={handleConfirmClick} disabled={isEmpty(selectedRowId) || isConfirming}>担当者確認{isConfirming ? <CircularProgress size={20} color="inherit" /> : ""}</Button>
        <Button variant="outlined" onClick={handleUnConfirmClick} disabled={isEmpty(selectedRowId) || isUnConfirming}>確認解除{isUnConfirming ? <CircularProgress size={20} color="inherit" /> : ""}</Button>
        <Button variant="contained" onClick={handleUploadClick} disabled={isEmpty(selectedRowId)}>修正版アップロード</Button>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleClose()
          }
        }}
      >
        <DialogTitle>ファイルアップロード</DialogTitle>
        <DialogContent>
          <DialogContentText>
            従業員情報を記載したExcelファイルを選択してください。
          </DialogContentText>
          <MuiFileInput value={uploadFile} onChange={handleInput} variant="outlined" />
          <br />
          <Typography variant="caption" component="div" gutterBottom>xlsx ファイルのみ、ファイルサイズは5MB以内。</Typography>
          {!(uploadFile) && (
            <Typography variant="caption" component="div" color="error.main" gutterBottom>ファイルを選択してください。</Typography>)}
          {(uploadFile) && !uploadFileIsExcelFile && (
            <Typography variant="caption" component="div" color="error.main" gutterBottom>このファイルタイプはサポートしていません。</Typography>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="submit" onClick={async () => handleSubmit(uploadFile)} disabled={isNil(uploadFile) || !uploadFileIsExcelFile}>送信</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={!isEmpty(alertStatus)} autoHideDuration={5000} onClose={handleAlertClose}>
        <Alert severity={alertStatus}>
          <AlertTitle>{!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertTitle"] : ""}</AlertTitle>
          {!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertMessage"] : ""}
        </Alert>
      </Snackbar>
    </Box>
  )
}