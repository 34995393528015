import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { Amplify } from 'aws-amplify'

import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import cognito from "./cognito.json"

const env = process.env['REACT_APP_ENV'] ?? 'dev'

const { userPoolId, clientId } = cognito[env as keyof typeof cognito]
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: clientId,
      userPoolId,
    }
  }
})

export const Admin = () => {
  return (
    <Box>
      <Authenticator hideSignUp>
        <Outlet />
      </Authenticator>
    </Box>
  )
}