import { useState } from 'react';
import { Alert, AlertColor, AlertTitle, Box, Button, Grid2, Snackbar, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { RegistCreditCard } from '../RegistCreditCard';
import { RequestConfirm } from '../RequestConfirm';
import { RequestForm } from '../RequestForm';
import { TermsAndConditions } from '../TermsAndConditions';
import { isNil } from 'lodash/fp';
import { createMemberId, getIsCreditRegistered, sendRequestData } from './functions';
import log from 'loglevel';
import { isEmpty } from 'lodash';
import { FormsProps } from '../../types';
import alertPropaties from './alert-propaties.json'
import { useAuth0, Auth0Context } from '@auth0/auth0-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

log.setLevel(log.levels.DEBUG)

type FormProgressProps = {
    contentNameArray: Array<string>,
    lastContent: JSX.Element
    buttonNames: {
        next: string,
        back: string,
        submit: string
    }
}

enum ActiveSteps {
    terms = 0,
    form = 1,
    credit = 2,
    confirm = 3
}


const getStepContent = (stepIndex: number, contentArray: Array<JSX.Element>): JSX.Element | undefined => {
    return stepIndex >= contentArray.length ? undefined : contentArray[stepIndex]
}

export const FormProgress = (props: FormProgressProps) => {
    const { user } = useAuth0(Auth0Context)
    const sub = user?.sub
    const { contentNameArray, lastContent, buttonNames } = props
    const [activeStep, setActiveStep] = useState<number>(0)

    const [companyName, setCompanyName] = useState<string>("")
    const [departmentName, setDepartmentName] = useState<string>("")
    const [post, setPost] = useState<string>("")
    const [familyNameKanji, setFamilyNameKanji] = useState<string>("")
    const [familyNameKana, setFamilyNameKana] = useState<string>("")
    const [firstNameKanji, setFirstNameKanji] = useState<string>("")
    const [firstNameKana, setFirstNameKana] = useState<string>("")
    const [industry, setIndustry] = useState<string>("")
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [faxNumber, setFaxNumber] = useState<string>("")
    const [mailAddress, setMailAddress] = useState<string>("")
    const [zipCode, setZipCode] = useState<string>("")
    const [prefecture, setPrefecture] = useState<string>("")
    const [address1, setAddress1] = useState<string>("")
    const [address2, setAddress2] = useState<string>("")
    const [address3, setAddress3] = useState<string>("")
    const [questionnair, setQuestionnair] = useState<string>("")
    const [optionalAnswer, setOptionalAnswer] = useState<string>("")
    const [memberId, setMemberId] = useState<string>("")

    const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(undefined)

    const steps = contentNameArray

    const defineSchema = {
        companyName: z.string()
            .min(1, { message: '会社名を入力してください' })
            .regex(/^[^_]*$/, 'アンダースコアは使用できません'),
        departmentName: z.string().min(1, { message: '部署名を入力してください' }),
        post: z.string().min(1, { message: '役職名を入力してください' }),
        familyNameKanji: z.string().min(1, { message: '姓を入力してください' }),
        firstNameKanji: z.string().min(1, { message: '名を入力してください' }),
        familyNameKana: z.string().min(1, { message: '姓を入力してください' }),
        firstNameKana: z.string().min(1, { message: '名を入力してください' }),
        industry: z.string().min(1, { message: '業種を入力してください' }),
        phoneNumber: z.string()
            .min(1, { message: '電話番号を入力してください' })
            .regex(/^[0-9]*$/, '半角数字のみで入力してください'),
        mailAddress: z.string()
            .min(1, { message: 'メールアドレスを入力してください' })
            .email({ message: '正しいメールアドレスを入力してください' }),
        zipCode: z
            .string()
            .min(7, { message: '正しい郵便番号(ハイフンなし)を入力してください' })
            .max(7, { message: '正しい郵便番号(ハイフンなし)を入力してください' })
            .regex(/^[0-9]{7}$/, '半角数字のみで入力してください'),
        prefecture: z.string().min(1, { message: '県を入力してください' }),
        address1: z.string().min(1, { message: '市区町村を入力してください' }),
        address2: z.string().min(1, { message: '番地以下を入力してください' }),
        questionnair: z
            .enum([
                "mzc_hp",
                "bizene_site",
                "bizene_mail",
                "dm",
                "flyer",
                "group_introduction",
                "org_introduction",
                "other"
            ], { message: '選択してください' }
            )
    }
    const schema = z.object(defineSchema)

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: zodResolver(schema),
    })

    const onSubmit: SubmitHandler<any> = (_data) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleNext = () => {
        if (activeStep === ActiveSteps.terms) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
        if (activeStep === ActiveSteps.form) {
        }
        if (activeStep === ActiveSteps.credit) {
            getIsCreditRegistered(memberId).then((result) => {
                if (!isNil(result) && result.data.success) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                } else {
                    setAlertStatus("error")
                }
            }).catch((log.error))
        }
        if (activeStep === ActiveSteps.confirm) {
            sendRequestData({
                companyName,
                departmentName,
                post,
                familyNameKanji,
                familyNameKana,
                firstNameKanji,
                firstNameKana,
                industry,
                phoneNumber,
                faxNumber,
                mailAddress,
                zipCode,
                prefecture,
                address1,
                address2,
                address3,
                questionnair,
                optionalAnswer,
                memberId
            }, sub as string).then((result) => {
                result ? setActiveStep((prevActiveStep) => prevActiveStep + 1) : setAlertStatus("error")
            })
        }
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleAlertClose = () => {
        setAlertStatus(undefined)
    }

    const formsProps: FormsProps = {
        register,
        setters: [
            setCompanyName,
            setDepartmentName,
            setPost,
            setFamilyNameKanji,
            setFamilyNameKana,
            setFirstNameKanji,
            setFirstNameKana,
            setIndustry,
            setPhoneNumber,
            setFaxNumber,
            setMailAddress,
            setZipCode,
            setPrefecture,
            setAddress1,
            setAddress2,
            setAddress3,
            setQuestionnair,
            setOptionalAnswer,
            setMemberId
        ],
        formData: {
            companyName,
            departmentName,
            post,
            familyNameKanji,
            familyNameKana,
            firstNameKanji,
            firstNameKana,
            industry,
            phoneNumber,
            faxNumber,
            mailAddress,
            zipCode,
            prefecture,
            address1,
            address2,
            address3,
            questionnair,
            optionalAnswer,
            memberId
        },
        errors
    }
    const contentArray = [
        <TermsAndConditions />,
        <RequestForm {...formsProps} />,
        <RegistCreditCard {...formsProps} />,
        <RequestConfirm {...formsProps} />
    ]

    if (isEmpty(memberId)) {
        createMemberId().then((newId) => {
            if (!isNil(newId)) setMemberId(newId)
        }).catch(log.error)
    }

    return (
        <Grid2 container>
            <Grid2 size={{ sm: 2 }} />
            <Grid2 size={{ lg: 8, sm: 8 }} spacing={10}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <Box>
                        {lastContent}
                    </Box>
                ) : (
                    <Box>
                        <Typography >{getStepContent(activeStep, contentArray)}</Typography>
                        <Box display={"flex"} justifyContent={"space-around"} paddingTop={3}>
                            <Box justifyContent={"start"}>
                                <Button variant="outlined"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    {buttonNames.back}
                                </Button>
                            </Box>
                            <Box justifyContent={"end"}>
                                <Button variant="contained" color="primary" onClick={activeStep === ActiveSteps.form ? handleSubmit(onSubmit) : handleNext}>
                                    {activeStep === steps.length - 1 ? buttonNames.submit : buttonNames.next}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Grid2>
            <Snackbar open={!isEmpty(alertStatus)} autoHideDuration={5000} onClose={handleAlertClose}>
                <Alert severity={alertStatus}>
                    <AlertTitle>{!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertTitle"] : ""}</AlertTitle>
                    {!isEmpty(alertStatus) ? alertPropaties[alertStatus as keyof typeof alertPropaties]["alertMessage"] : ""}
                </Alert>
            </Snackbar>
        </Grid2>
    )
}

export default FormProgress