import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Box, Link, Typography } from "@mui/material"
import { Loading } from "../Loading"

const PageNotFound = () => {
    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">お探しのページが見つかりませんでした。</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography marginY={1}>新規お申し込みの方は<Link href="/request/" component={"a"}>こちら</Link></Typography>
                <Typography marginY={1}>ご利用中の方は<Link href="/mypage/" component={"a"} >こちら</Link></Typography>
            </Box>
        </Box>
    )
}

export default withAuthenticationRequired(PageNotFound, { onRedirecting: () => (<Loading />) })