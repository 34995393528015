import { Auth0Provider as Provider } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

import openIdConfig from './openIdConfig.json'

const env = process.env['REACT_APP_ENV'] ?? 'dev'

const {
    domain,
    clientId
} = openIdConfig[env as keyof typeof openIdConfig]
const redirectUri = window.location.origin

const Auth0Provider = ({ children }: { children: any }) => {
    const navigate = useNavigate()
    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname)
    }

    return (
        <Provider domain={domain} clientId={clientId} authorizationParams={{ redirect_uri: redirectUri }} onRedirectCallback={onRedirectCallback}>
            {children}
        </Provider>
    )
}

export default Auth0Provider