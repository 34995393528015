import axios from "axios"
import { isNil } from "lodash/fp"
import { FormInputDatas } from "../../types"
import log from "loglevel"
import { inspect } from "./inspect"
import { createHash } from "./api"

log.setLevel(log.levels.DEBUG)

const env = process.env['REACT_APP_ENV'] ?? 'dev'


const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

export const getIsCreditRegistered = async (id: string) => {
    const result = await axios.get(`https://${domainName}/v1/request/is-credit-regist/${id}`)
    if (isNil(result)) {
        return undefined
    }
    return result
}

export const createMemberId = async (): Promise<string | undefined> => {
    const baseCodePoint = 'A'.codePointAt(0)
    if (isNil(baseCodePoint)) {
        return undefined
    }
    while (true) {
        const newId = Array.from(new Array(10), (_e, index) => {
            if (index < 4) return String.fromCodePoint(baseCodePoint + Math.floor(Math.random() * 25))
            return Math.floor(Math.random() * 10)
        }).join("")
        const result = await axios.get(`https://${domainName}/v1/request/memberid-duplex-check/${newId}`)
        const duplex = result.data.duplex as boolean
        if (!duplex) {
            return newId
        }
    }
}

export const sendRequestData = async (data: FormInputDatas, sub: string): Promise<boolean> => {
    const subHash = await createHash('SHA-256', sub)
    const sendData = {
        sub: subHash,
        ...data
    }
    const result = await axios.post(`https://${domainName}/v1/request/send-request`, sendData)
    if (result.status === 202) {
        return true
    }
    log.error(`[functions.sendRequestData]`, inspect(result))
    return false
}

