import axios, { AxiosHeaders } from "axios"
import { RequestsDataColumns } from "./types"
import { inspect } from "./inspect"
import log from 'loglevel'
import { getCustomHeaders } from "../Admin/functions"
import { isNil } from "lodash/fp"

const env = process.env['REACT_APP_ENV'] ?? 'dev'


const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`


export const getRequestRows = async (): Promise<RequestsDataColumns[] | undefined> => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.get(`https://${domainName}/v1/admin/request-files`,
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        })
    console.log(`[functions.getRows] ${inspect({ result })}`)
    if (result.status === 200) {
        return result.data.data as RequestsDataColumns[]
    }
    if (result.status === 500) {
        log.error('[functions.getRows]', inspect({ result }))
    }
    return undefined
}

export const confirmRequest = async (key: string, closingDate: string, startDate: string, autoPayment: string) => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(`https://${domainName}/v1/admin/confirm-request`, {
        key,
        closingDate,
        startDate,
        autoPayment
    },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        })
    if (result.status === 200) {
        return result.data.data as RequestsDataColumns[]
    }
    if (result.status === 500) {
        log.error('[functions.getRows]', inspect({ result }))
    }
    return undefined
}

export const getRequest = async (key: string): Promise<any> => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(`https://${domainName}/v1/admin/get-request`, {
        key
    },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        })
    console.log(`[functions.getRequest] ${inspect({ result })}`)
    if (result.status === 200) {
        return result.data.request
    }
    if (result.status === 500) {
        log.error('[functions.getRequest]', inspect({ result }))
    }
    return undefined
}