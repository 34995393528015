import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { Box } from "@mui/material"
import { isNil } from "lodash/fp"
import log from "loglevel"
import { useState } from "react"
import { Outlet } from "react-router-dom"
import { Loading } from "../Loading"
import { UserAuthError } from "../UserAuthError"
import { authUser } from "./api"


const MyPage = () => {
    const { user } = useAuth0()
    const [isAuth, setIsAuth] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [memberId, setMemberId] = useState<string | undefined>(undefined)
    const [name, setName] = useState<string | undefined>(undefined)
    const [autoPayment, setAutoPayment] = useState<string | undefined>(undefined)
    if (isNil(user) || isNil(user.sub)) {
        setIsAuth(false)
        setIsLoading(false)
    } else {
        const { sub } = user
        if (!isAuth && isLoading) {
            authUser(sub).then((result: {
                success: boolean,
                memberId?: string,
                name?: string,
                autoPayment?: string
            }) => {
                if (!isNil(result)) {
                    setIsAuth(result.success)
                    setMemberId(result.memberId)
                    setName(result.name)
                    setAutoPayment(result.autoPayment)
                    setIsLoading(false)
                }
            }).catch(log.error)
        }
    }
    return (
        <Box>
            {isLoading ? <Loading /> : isAuth ? <Outlet context={{ memberId, name, autoPayment }} /> : <UserAuthError />}
        </Box>
    )
}

export default withAuthenticationRequired(MyPage, { onRedirecting: () => (<Loading />) })