import util from 'util'

import { pick } from 'lodash/fp'

const pickRequest = pick([
  'baseURL',
  'method',
  'url',
  'params',
  'headers',
  'data'
])
const pickResponse = pick(['status', 'statusText', 'headers', 'data'])
const opts = {
  showHidden: false,
  depth: Infinity,
  colors: false,
  customInspect: false,
  showProxy: false,
  maxArrayLength: Infinity,
  maxStringLength: Infinity,
  breakLength: Infinity,
  compact: false,
  sorted: false,
  getters: false
}

export const inspect = (value: any): string => {
  if (value.isAxiosError as boolean) {
    const { name, message, stack } = value
    const request = pickRequest(value.response?.config ?? value.config)
    const response = pickResponse(value.response)
    return util.inspect({ name, message, stack, request, response }, opts)
  }
  return util.inspect(value, opts)
}
