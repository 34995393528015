import { isNil } from "lodash/fp"
import * as Xlsx from "xlsx"
import axios from 'axios'
import { inspect } from "./inspect"
import { createHash } from "./api"

const env = process.env['REACT_APP_ENV'] ?? 'dev'


type EmployeeExcel = {
    data: any
}
const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

export const xlsx2Json = async (excel: File, sheetName?: string): Promise<string> => {
    const uploadFileBuffer = await excel.arrayBuffer()
    const workbook = Xlsx.read(uploadFileBuffer, { type: 'buffer', bookVBA: true })
    let worksheet = workbook.Sheets[workbook.SheetNames[0]]
    if (!isNil(sheetName)) {
        worksheet = workbook.Sheets[sheetName]
    }
    const excelJson = Xlsx.utils.sheet_to_json(worksheet)
    const employeeExcelData: EmployeeExcel = {
        data: excelJson
    }
    const jsonData = JSON.stringify(employeeExcelData)
    return jsonData
}

export const doUpload = async (
    memberId: string,
    sub: string,
    file: string,
    companyName: string
): Promise<number | undefined> => {
    const subHash = await createHash('SHA-256', sub)
    const result = await axios.post(
        `https://${domainName}/v1/users/upload`,
        {
            memberId,
            fileData: file,
            companyName
        },
        {
            headers: {
                Authorization: `${memberId}.${subHash}`
            }
        }
    )
    console.log(`[functions.doUpload] ${inspect({ result })}`)
    return result.status
}

export const doDownload = async (
    memberId: string,
    sub: string,
    fileName: string
): Promise<any> => {
    const subHash = await createHash('SHA-256', sub)
    const result = await axios.get(
        `https://${domainName}/v1/users/download/${memberId}`,
        {
            headers: {
                Authorization: `${memberId}.${subHash}`
            }
        }
    )
    const buffer = Buffer.from(result.data, 'base64')
    console.log(`[functions.doDownload] ${inspect({ result })}`)
    const url = window.URL.createObjectURL(new Blob([buffer]))
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    a.remove()
    return result.data
}

export const doTemplateDownload = async (
    memberId: string,
    sub: string
): Promise<any> => {
    const subHash = await createHash('SHA-256', sub)
    const result = await axios.get(
        `https://${domainName}/v1/users/download/template`,
        {
            headers: {
                Authorization: `${memberId}.${subHash}`
            }
        }
    )
    const buffer = Buffer.from(result.data, 'base64')
    console.log(`[functions.doDownload] ${inspect({ result })}`)
    const url = window.URL.createObjectURL(new Blob([buffer]))
    const a = document.createElement('a')
    a.href = url
    a.download = "EmployeeRegistForm.xlsx"
    a.click()
    a.remove()
    return result.data
}
