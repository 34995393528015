import { Box, Button, Typography } from "@mui/material"


export const GmoFailed = () => {
    const handleButtonClick = () => {
        window.close()
    }
    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">お支払情報の登録に失敗しました。</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography>システムエラーにより、お客様のクレジットカード情報の登録が完了しませんでした。</Typography>
                <Typography>大変お手数ではございますが、お申し込み画面にお戻りいただき、「お支払情報のご登録」より再度ご登録をお願いいたします。</Typography>
            </Box>
            <Box
                display={"flex"}
                paddingTop={3}
                justifyContent={"center"}>
                <Button variant="text" onClick={handleButtonClick}>タブを閉じる</Button>
            </Box>
        </Box>
    )
}

export const GmoSuccess = () => {
    const handleButtonClick = () => {
        window.close()
    }
    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">お支払情報の登録が完了しました。</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography>お客様のお支払情報を登録いたしました。</Typography>
                <Typography>このタブを閉じてお申し込み画面にお戻りいただき、お申し込み手続きを進めてください。</Typography>
            </Box>
            <Box
                display={"flex"}
                paddingTop={3}
                justifyContent={"center"}>
                <Button variant="text" onClick={handleButtonClick}>タブを閉じる</Button>
            </Box>
        </Box>
    )
}