import { BottomNavigation, Box, BoxProps, Typography } from "@mui/material"

export const Footer = (props: BoxProps) => {
    return (
        <Box  display="flex" justifyContent="center" alignContent="center" {...props}>
            <BottomNavigation sx={{ position:"relative",  height:15}}>
                <Box textAlign={"center"}>
                    <Typography variant="caption">(c) Chubu Electric Power Miraiz Connect, Inc. All Rights Reserved.</Typography>
                </Box>
            </BottomNavigation>
        </Box>
    )
}
