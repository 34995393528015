import { createHash } from "./api"
import { Buffer } from 'buffer';
import gmoKeys from '../RegistCreditCard/gmo-keys.json'

// @ts-ignore
window.Buffer = Buffer;


const env = process.env['REACT_APP_ENV'] ?? 'dev'

const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

const {
    id: shopId,
    password: shopPassword,
    domain,
    configid
} = gmoKeys[env as keyof typeof gmoKeys]


export const getShopInfo = () => {
    return {
        domain,
        shopId
    }
}

export const createParams = async (memberId: string, companyName: string) => {
    const now = new Date()
    const param = {
        configid,
        member: {
            MemberID: memberId,
            MemberName: companyName,
            Cardeditno: now.getTime(),
            CompleteUrl: `https://${domainName}/v1/users/credit-regist/${memberId}`
        }
    }
    const paramString = JSON.stringify(param)
    const base64Buffer = Buffer.from(paramString).toString("base64")
    const hashParam = await createHash("SHA-256", base64Buffer + shopPassword)
    const result = `${base64Buffer}.${hashParam}`
    return result
}
