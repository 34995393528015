import { isNil } from "lodash/fp"
import {
    CognitoUserPool,
    type CognitoUserSession
} from 'amazon-cognito-identity-js'
import log from "loglevel"
import cognito from "./cognito.json"
const env = process.env['REACT_APP_ENV'] ?? 'dev'

const { userPoolId: UserPoolId, clientId: ClientId } =
    cognito[env as keyof typeof cognito]
const userPool = new CognitoUserPool({
    UserPoolId,
    ClientId
})
export const getCustomHeaders = (
): Headers | undefined => {
    const cognitoUser = userPool.getCurrentUser()
    if (isNil(cognitoUser)) return undefined
    let session: any
    cognitoUser.getSession(
        (error: Error, newSession: CognitoUserSession | null) => {
            if (!isNil(error)) {
                log.error('[DataProvider.fetchJson]', { error })
                return
            }
            session = newSession
        }
    )
    if (isNil(session)) return undefined
    const customHeaders = new Headers({
        Accept: 'application/json'
    }) as Headers
    customHeaders.set('Authorization', session.getIdToken().getJwtToken())
    return customHeaders
}
