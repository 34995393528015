import { Alert, AlertColor, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Snackbar, Typography, CircularProgress } from "@mui/material"
import { useState } from "react"
import { isEmpty, isNil } from "lodash/fp"
import { doDownload, doTemplateDownload, doUpload, xlsx2Json } from "./functions"
import { MuiFileInput } from "mui-file-input"
import { ArrowBackIosNewRounded, CloudDownloadOutlined, CloudUploadOutlined, Group, GroupOutlined } from "@mui/icons-material"
import alertPropaties from './alert-propaties.json'
import { useOutletContext } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import log from "loglevel"
import { inspect } from "./inspect"
import { AlertPropaty, MypageOutletContext } from "../../types"

export const Employee = () => {
    const { memberId, name } = useOutletContext<MypageOutletContext>()
    const { user } = useAuth0()
    const sub = user?.sub as string
    const [uploadFile, setUploadFile] = useState<File | null>(null)
    const [uploadFileIsExcelFile, setUploadFileIsExcelFile] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [alertPropaty, setAlertPropaty] = useState<AlertPropaty>(undefined)
    const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(undefined)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)

    const handleInput = (uploadFile: File | null) => {
        setUploadFile(uploadFile)
        if (!isNil(uploadFile) && (uploadFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            setUploadFileIsExcelFile(true)
        } else {
            setUploadFileIsExcelFile(false)
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setUploadFile(null)
        setOpen(false)
    }

    const handleSubmit = async (uploadFile: File | null) => {
        if (!isNil(uploadFile)) {
            if (!isNil(memberId)) {
                const data = await xlsx2Json(uploadFile, "入力表")
                setAlertPropaty(alertPropaties['upload'])
                doUpload(memberId, sub, data, name).then((result) => {
                    if (!isNil(result)) {
                        setAlertStatus("success")
                    } else {
                        setAlertStatus("error")
                    }
                }).catch((error) => {
                    log.error(`[index.handleSubmit] Upload failed.`, inspect({ error }))
                    setAlertStatus("error")
                })
            }
        } else {
            setAlertStatus("error")
        }
        handleClose()
    }

    const handleDownload = () => {
        setIsDownloading(true)
        const fileName = "EmployeeRegistForm.xlsx"
        setAlertPropaty(alertPropaties['download'])
        doDownload(memberId, sub, fileName).then((_data) => {
            setIsDownloading(false)
        }).catch((error) => {
            log.error(error)
            setAlertStatus("error")
            setIsDownloading(false)
        })
    }

    const handleAlertClose = () => {
        setAlertStatus(undefined)
        setAlertPropaty(undefined)
    }

    const handleDownloadLinkClick = () => {
        doTemplateDownload(memberId, sub).then((_data) => {
        }).catch(log.error)
    }

    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">従業員情報</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography>新規お申し込みの方は、<Link component={"a"} onClick={handleDownloadLinkClick}>こちら</Link>のリンクより従業員ファイルをダウンロードしてください。</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3}>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><Group />従業員の会員ID・初期PWを確認(Excelファイルをダウンロード)</Typography>
                        <Button variant="contained" sx={{ marginY: 1 }} onClick={handleDownload} disabled={isDownloading}><CloudDownloadOutlined />ダウンロード{isDownloading ? <CircularProgress size={20} color="inherit" /> : ""}</Button>
                    </Box>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Typography display={"flex"} alignContent={"center"}><GroupOutlined />従業員の情報を変更(Excelファイルをアップロード)</Typography>
                        <Button variant="contained" onClick={handleClickOpen} sx={{ marginY: 1 }}><CloudUploadOutlined />アップロード</Button>
                    </Box>
                    <Button sx={{ marginTop: 5 }} variant="outlined" LinkComponent={"a"} href="/mypage"><ArrowBackIosNewRounded />戻る</Button>
                </Box>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault()
                        handleClose()
                    }
                }}
            >
                <DialogTitle>ファイルアップロード</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        従業員情報を記載したExcelファイルを選択してください。
                    </DialogContentText>
                    <MuiFileInput value={uploadFile} onChange={handleInput} variant="outlined" />
                    <br />
                    <Typography variant="caption" component="div" gutterBottom>xlsx ファイルのみ、ファイルサイズは5MB以内。</Typography>
                    {!(uploadFile) && (
                        <Typography variant="caption" component="div" color="error.main" gutterBottom>ファイルを選択してください。</Typography>)}
                    {(uploadFile) && !uploadFileIsExcelFile && (
                        <Typography variant="caption" component="div" color="error.main" gutterBottom>このファイルタイプはサポートしていません。</Typography>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <Button type="submit" onClick={async () => handleSubmit(uploadFile)} disabled={isNil(uploadFile) || !uploadFileIsExcelFile}>送信</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={!isEmpty(alertStatus)} autoHideDuration={5000} onClose={handleAlertClose}>
                <Alert severity={alertStatus}>
                    <AlertTitle>{!isEmpty(alertStatus) && !isNil(alertPropaty) ? alertPropaty[alertStatus as keyof typeof alertPropaty]["alertTitle"] : ""}</AlertTitle>
                    {!isEmpty(alertStatus) && !isNil(alertPropaty) ? alertPropaty[alertStatus as keyof typeof alertPropaty]["alertMessage"] : ""}
                </Alert>
            </Snackbar>
        </Box>
    )
}