import { useAuth0 } from "@auth0/auth0-react"
import { AppBar, Box, BoxProps, IconButton, Typography } from "@mui/material"
import { Logout } from "@mui/icons-material"
import Logo from "./images/logo_img01.png"
import { red } from "@mui/material/colors"
import { Link } from "react-router-dom"
import { signOut } from "aws-amplify/auth"

export const Header = (props: BoxProps) => {
    const { logout } = useAuth0()
    const logoutUrl = window.location.origin + "/logout"
    const isInLogoutPage = window.location.pathname === logoutUrl

    const onLogoutButtonClick = () => {
        logout({ logoutParams: { returnTo: logoutUrl } })
        signOut()
    }

    const fontFamily = [
        "メイリオ",
        "Meiryo",
        "ヒラギノ角ゴ Pro W3",
        "Hiragino Kaku Gothic Pro",
        "Osaka",
        "ＭＳ Ｐゴシック",
        "MS PGothic",
        "sans-serif"
    ]
    return (
        <Box {...props}>
            <AppBar component="header" position="relative" elevation={1} sx={{ borderWidth: 0, height: 64, backgroundColor: "transparent" }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box display={"flex"}>
                        <Link to={"http://miraiz-connect.co.jp"} target="_blank">
                            <Box
                                component="img"
                                sx={{
                                    height: 64
                                }}
                                alt="Logo"
                                src={Logo}
                                position={"relative"}
                            /></Link>
                        <Box alignContent={"center"} paddingLeft={2}>
                            <Typography fontWeight={"bold"} fontSize={15} fontFamily={fontFamily} color={red["A200"]}>
                                コネクトWELBOX
                            </Typography>
                        </Box>
                    </Box>
                    <Box alignContent={"center"} justifyContent={"end"}>
                        {!isInLogoutPage ? <IconButton color="primary" sx={{ fontSize: 12 }} onClick={onLogoutButtonClick}><Logout />Logout</IconButton> : <Box></Box>}
                    </Box>
                </Box>
            </AppBar>
        </Box>
    )
}

