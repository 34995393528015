import { Box, Container, CircularProgress } from "@mui/material"

export const Loading = () => {
    return (
        <Container>
            <Box display="grid" justifyContent={"center"} alignContent={"center"}>
                <CircularProgress />
            </Box>
        </Container>
    )
}