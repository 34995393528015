import { ArrowBackIosNewRounded, CreditCardOutlined, BusinessRounded} from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"

export const Profile = () => {
  return (
    <Box display="grid" justifyContent={"center"}>
      <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">ご登録情報の変更</Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3}>
          <Box display="grid" paddingTop={3} color="gray">
            <Typography display={"flex"} alignContent={"center"}><CreditCardOutlined />クレジットカード情報を変更される方はこちら</Typography>
            <Button variant="contained" LinkComponent={"a"} href="/mypage/profile/change-credit">クレジットカードの変更</Button>
          </Box>
          <Box display="grid" paddingTop={3} color="gray">
            <Typography display={"flex"} alignContent={"center"}><BusinessRounded />契約企業情報を変更される方はこちら</Typography>
            <Button variant="contained" LinkComponent={"a"} href="/mypage/profile/change-company-info">契約企業情報の変更</Button>
          </Box>
          <Button sx={{ marginTop: 5 }} variant="outlined" LinkComponent={"a"} href="/mypage"><ArrowBackIosNewRounded />戻る</Button>
        </Box>
      </Box>
    </Box>
  )
}