import { Box, Button, Typography } from "@mui/material"
import { createParams, getShopInfo } from "./functions"
import { useEffect, useState } from "react"
import { isEmpty, isNil } from "lodash/fp"
import { ArrowBackIosNewRounded, CreditCardOutlined } from "@mui/icons-material"
import { useOutletContext } from "react-router-dom"
import { MypageOutletContext } from "../../types"

export const ChangeCreditCard = () => {
    const [url, setUrl] = useState<string>("")
    const { memberId, name } = useOutletContext<MypageOutletContext>()
    const shopInfo = getShopInfo()
    const {
        domain,
        shopId
    } = shopInfo
    useEffect(() => {
        createParams(memberId, name).then((param) => {
            setUrl(`https://${domain}/v1/plus/${shopId}/member/${param}`)
        })
    }, [])

    const handleButtonClick = () => {
        if (!isNil(url)) {
            window.open(url, '_blank')
        }
    }

    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">お支払い方法の変更</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography>現在のお支払い方法はクレジット決済のみとなります。</Typography>
                <Typography>下記のボタンを押すと、決済代行会社の画面へ遷移します。</Typography>
                <Typography>お申込みには、お支払い情報の入力が必須となります。</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3} width={500}>
                    <Box display="grid" paddingTop={3} color="gray">
                        <Button variant="contained" disabled={isEmpty(url)} onClick={handleButtonClick}><CreditCardOutlined />お支払い情報の変更</Button>
                    </Box>
                    <Button sx={{ marginTop: 5 }} variant="outlined" LinkComponent={"a"} href="/mypage/profile"><ArrowBackIosNewRounded />戻る</Button>
                </Box>
            </Box>
        </Box>
    )
}