import axios, { AxiosHeaders } from "axios"
import { EmployeeDataColumns } from "./types"
import { inspect } from "./inspect"
import log from 'loglevel'
import { getCustomHeaders } from "../Admin/functions"
import { isNil } from "lodash/fp"
import * as Xlsx from "xlsx"
const env = process.env['REACT_APP_ENV'] ?? 'dev'


type EmployeeExcel = {
    data: any
}
const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`


export const getRows = async (): Promise<EmployeeDataColumns[] | undefined> => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.get(`https://${domainName}/v1/admin/upload-files`,
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        }
    )
    console.log(`[functions.getRows] ${inspect({ result })}`)
    if (result.status === 200) {
        return result.data.data as EmployeeDataColumns[]
    }
    if (result.status === 500) {
        log.error('[functions.getRows]', inspect({ result }))
    }
    return undefined
}

export const confirmFile = async (key: string) => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(`https://${domainName}/v1/admin/confirm-file`, {
        key
    },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        }
    )
    if (result.status === 200) {
        return result.data.data as EmployeeDataColumns[]
    }
    if (result.status === 500) {
        log.error('[functions.getRows]', inspect({ result }))
    }
    return undefined
}

export const unConfirm = async (
    key: string
) => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(`https://${domainName}/v1/admin/un-confirm-file`, {
        key
    },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        }
    )
    if (result.status === 200) {
        return result.data.data as EmployeeDataColumns[]
    }
    if (result.status === 500) {
        log.error('[functions.getRows]', inspect({ result }))
    }
    return undefined
}

export const doDownload = async (
    key: string,
    fileName: string,
    companyName: string
): Promise<any> => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(
        `https://${domainName}/v1/admin/download`,
        {
            key,
            companyName
        },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        }
    )
    const buffer = Buffer.from(result.data, 'base64')
    console.log(`[functions.doDownload] ${inspect({ result })}`)
    const url = window.URL.createObjectURL(new Blob([buffer]))
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    a.remove()
    return result.data
}

export const xlsx2Json = async (excel: File, sheetName?: string): Promise<string> => {
    const uploadFileBuffer = await excel.arrayBuffer()
    const workbook = Xlsx.read(uploadFileBuffer, { type: 'buffer', bookVBA: true })
    let worksheet = workbook.Sheets[workbook.SheetNames[0]]
    if (!isNil(sheetName)) {
        worksheet = workbook.Sheets[sheetName]
    }
    const excelJson = Xlsx.utils.sheet_to_json(worksheet)
    const employeeExcelData: EmployeeExcel = {
        data: excelJson
    }
    const jsonData = JSON.stringify(employeeExcelData)
    return jsonData
}

export const doUpload = async (
    memberId: string,
    file: string,
    companyName: string
): Promise<number | undefined> => {
    const customHeaders = getCustomHeaders() as AxiosHeaders | undefined
    if (isNil(customHeaders)) {
        return undefined
    }
    const result = await axios.post(
        `https://${domainName}/v1/admin/upload`,
        {
            memberId,
            fileData: file,
            companyName
        },
        {
            headers: {
                Authorization: customHeaders.get('Authorization')
            }
        }
    )
    console.log(`[functions.doUpload] ${inspect({ result })}`)
    return result.status
}
