import axios from "axios"

const env = process.env['REACT_APP_ENV'] ?? 'dev'


const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

export const authUser = async (sub: string): Promise<{
    success: boolean,
    memberId: string,
    name?: string,
    autoPayment?: string
}> => {
    const hash = await createHash('SHA-256', sub)
    const result = await axios.get(`https://${domainName}/v1/users/auth`, {
        headers: {
            Authorization: hash
        }
    })
    return result.data
}

export const createHash = async (alg: string, seed: string) => {
    const encoder = new TextEncoder()
    const buffer = encoder.encode(seed)
    const hashBuffer = await crypto.subtle.digest(alg, buffer)
    const hash = Array.from(new Uint8Array(hashBuffer), (byte) => byte.toString(16).padStart(2, '0')).join("")
    return hash
}
