import { Box, Button, Paper, Typography } from "@mui/material"
import { GridColDef, DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { isNil } from "lodash/fp"
import log from "loglevel"
import { ArrowBackIosNewRounded } from "@mui/icons-material"
import { getInvoiceHistory, getSubscription, InvoiceHistories } from "./functions"
import { Auth0Context, useAuth0 } from "@auth0/auth0-react"
import { useOutletContext } from "react-router-dom"
import { MypageOutletContext } from "../../types"

type SubscriptionRow = {
  id: string,
  plan: string,
  start: string
}

export const InvoiceHistory = () => {
  const [invoiceRows, setInvoiceRows] = useState<Array<InvoiceHistories>>([])
  const [subscription, setSubscription] = useState<Array<SubscriptionRow>>([])
  const { user } = useAuth0(Auth0Context)
  const sub = user?.sub as string
  const { memberId } = useOutletContext<MypageOutletContext>()
  useEffect(() => {
    getSubscription(memberId, sub).then((result) => {
      if (!isNil(result)) {
        setSubscription([{ id: "福利厚生プラン", plan: result.plan, start: result.start }])
      }
    }).catch(log.error)
    getInvoiceHistory(memberId, sub).then((result) => {
      if (!isNil(result)) {
        setInvoiceRows(result)
      }
    }).catch(log.error)
  }, [])


  const subscriptionColumns: GridColDef[] = [
    { field: 'id', headerName: 'プラン', width: 200 },
    { field: 'plan', headerName: 'コース', width: 200 },
    { field: 'start', headerName: '開始日', width: 300 }
  ]

  const invoiceColumns: GridColDef[] = [
    { field: 'id', headerName: '日付', width: 200 },
    { field: 'amount', headerName: '請求額', width: 300 }
  ]



  const paginationModel = { page: 0, pageSize: 50 }
  return (
    <Box display={"grid"} justifyContent={"center"}>
      <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">ご契約情報とご利用明細の確認</Typography>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3} color="gray">
        <Box marginTop={3}>
          <Typography display={"flex"} alignContent={"center"}>ご契約内容の確認</Typography>
          <Paper sx={{ height: "400", width: '100%' }}>
            <DataGrid
              rows={subscription}
              columns={subscriptionColumns}
              initialState={{ pagination: { paginationModel }, sorting: { sortModel: [{ field: 'uploadAt', sort: 'desc' }] } }}
              pageSizeOptions={[1, 1]}
              disableRowSelectionOnClick
              sx={{ border: 0 }}
            />
          </Paper>
        </Box>
        <Box marginTop={3}>
          <Typography display={"flex"} alignContent={"center"}>ご利用明細の確認</Typography>
          <Paper sx={{ height: "400", width: '100%' }}>
            <DataGrid
              rows={invoiceRows}
              columns={invoiceColumns}
              initialState={{ pagination: { paginationModel }, sorting: { sortModel: [{ field: 'uploadAt', sort: 'desc' }] } }}
              pageSizeOptions={[5, 10]}
              sx={{ border: 0 }}
            />
          </Paper>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} marginTop={3}>
        <Button sx={{ marginTop: 5 }} variant="outlined" LinkComponent={"a"} href="/mypage"><ArrowBackIosNewRounded />戻る</Button>
      </Box>
    </Box>
  )
}