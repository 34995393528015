import { Box, Typography, Link } from "@mui/material"

export const UserAuthError = () => {
    return (
        <Box display="grid" justifyContent={"center"}>
            <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">ご登録情報が確認できませんでした</Typography>
            <Box display="grid" justifyContent={"center"} paddingTop={3} color="gray">
                <Typography>コネクトWELBOXをご利用いただきましてありがとうございます。</Typography>
                <Typography>大変恐れ入りますが、お客様のご登録情報が確認できませんでした。</Typography>
                <Typography>まだお申し込みいただいていない場合は、<Link component={"a"} href="https://bizene.chuden.jp/member/p/marche/bpo/index.html">こちら</Link>よりお申し込みをお願いいたします。</Typography>
            </Box>
        </Box>
    )
}