import axios from "axios"
import { createHash } from "./api"

const env = process.env['REACT_APP_ENV'] ?? 'dev'

const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

export type ChangeCompanyData = {
    companyName: string
    departmentName: string
    post: string
    familyNameKanji: string
    familyNameKana: string
    firstNameKanji: string
    firstNameKana: string
    industry: string
    phoneNumber: string
    faxNumber: string
    mailAddress: string
    zipCode: string
    prefecture: string
    address1: string
    address2: string
    address3: string
}

export const changeCompany = async (memberId: string, sub: string, sendData: ChangeCompanyData): Promise<{ success: boolean }> => {
    const subHash = await createHash('SHA-256', sub)
    const result = await axios.post(`https://${domainName}/v1/users/company/${memberId}`,
        sendData,
        {
            headers: {
                Authorization: `${memberId}.${subHash}`
            }
        }
    )
    return result.data
}
export const getCompany = async (memberId: string, sub: string): Promise<ChangeCompanyData> => {
    const subHash = await createHash('SHA-256', sub)
    const result = await axios.get(`https://${domainName}/v1/users/company/${memberId}`,
        {
            headers: {
                Authorization: `${memberId}.${subHash}`
            }
        }
    )
    return result.data
}
