import { Box, Container } from "@mui/material"
import Progress from "../Progress"
import { ThankyouForRequest } from '../ThankyouForRequest';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "../Loading";


const RequestBase = () => {
    const contentNameArray = [
        'ご利用規約',
        '企業情報の入力',
        'お支払い方法の設定',
        '入力情報の確認'
    ]
    const buttonNames = {
        next: "次へ",
        back: "戻る",
        submit: "お申し込み"
    }
    return (
        <Box>
            <Container maxWidth="xl">
                <Progress contentNameArray={contentNameArray} buttonNames={buttonNames} lastContent={<ThankyouForRequest />} />
            </Container>
        </Box>
    )
}

export default withAuthenticationRequired(RequestBase, { onRedirecting: () => (<Loading />) })