import * as React from "react"

import { Route, Routes } from "react-router-dom";
import MyPage from './components/MyPage/index'
import { Box } from "@mui/material"
import RequestBase from "./components/RequestBase";
import { AdminMenu } from "./components/AdminMenu";
import { MyPageTop } from "./components/MyPageTop";
import { Employee } from "./components/Employee";
import { Admin } from "./components/Admin";
import { AdminRequests } from "./components/AdminRequests";
import { AdminEmployees } from "./components/AdminEmployee";
import { ChangeCreditCard } from "./components/ChangeCreditCard";
import PageNotFound from "./components/PageNotFound";
import { Profile } from "./components/Profile";
import { Header } from "./components/Header"
import { Footer } from "./components/Footer";
import { GmoSuccess, GmoFailed } from "./components/GmoResult";
import { InvoiceHistory } from "./components/InvoiceHistory";
import { ChangeCompanyInfo } from "./components/ChangeCompanyInfo";
import LogoutPage from "./components/LogoutPage";


const App = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} flexWrap={"nowrap"} height={"98vh"}>
      <Header flex={0} />
      <Box flex={1} height={"2000px"} overflow={"auto"} paddingTop={5}>
        <Routes>
          <Route path='/request' element={<RequestBase />} />
          <Route path='/gmo/success' element={<GmoSuccess />} />
          <Route path='/gmo/failed' element={<GmoFailed />} />

          <Route path='/mypage' element={<MyPage />}>
            <Route path='/mypage/' element={<MyPageTop />} />
            <Route path='/mypage/profile' element={<Profile />} />
            <Route path='/mypage/employee' element={<Employee />} />
            <Route path='/mypage/invoice-history' element={<InvoiceHistory />} />
            <Route path='/mypage/profile/change-company-info' element={<ChangeCompanyInfo />} />
            <Route path='/mypage/profile/change-credit' element={<ChangeCreditCard />} />
          </Route>

          <Route path='/admin' element={<Admin />} >
            <Route path="/admin" element={<AdminMenu />} />
            <Route path="/admin/requests" element={<AdminRequests />} />
            <Route path="/admin/employees" element={<AdminEmployees />} />
          </Route>

          <Route path='/logout' element={<LogoutPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Box>
      <Footer flex={0} />
    </Box>
  )
}

export default App