import { Box, Container, Paper, Typography } from "@mui/material"

export const TermsAndConditions = () => {
    return (
        <Container>
            <Box paddingTop={5}>
                <Paper sx={{maxHeight:500 ,overflow: "auto"}}>
                    <Typography align="center" fontWeight={"bold"}>企業利用規約</Typography>

                    <Box fontSize={15}>
                        <p><span>&nbsp;</span>
                    </p>

                    <p><span>本企業利用規約（以下「本規約」といいます）は、契約企業（第２条に定めるとおりとします）が、中部電力ミライズコネクト株式会社（以下「事業者」といいます）が手掛ける「中部電力ミライズコネクト企業お客様向け<span>BPO</span>サービス」内の「コネクト<span>WELBOX</span>（<span>BtoB</span>）」を利用するにあたり、契約企業・事業者間で必要な事項について定めるものです。</span></p>

                    <p><span>本サービスの利用に際しては、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１条（目的）</span></p>

                    <p><span>契約企業は、事業者が手掛ける「中部電力ミライズコネクト企業お客様向け<span>BPO</span>サービス」内の「コネクト<span>WELBOX</span>（<span>BtoB</span>）」を利用するにあたり、本規約の内容に同意するものとします。</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２条（用語の定義）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>「本サービス」とは、事業者が提供するコネクト<span>WELBOX</span>（<span>BtoB</span>）を指します。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>「契約企業」とは、本サービスに関する本規約に同意の上、本サービスに申し込み、事業者が承認した企業・団体をいいます。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>「会員」とは、契約企業に所属する者（役員、従業員を含みます）で、契約企業が第５条、第９条に従い登録対象者として指定した者のうち、事業者が定める方法により、所定の手続きを完了した者をいいます。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第３条（福利厚生施策支援コンテンツの利用）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、福利厚生施策支援コンテンツ（<span>WELBOX</span>）の<span>WELBOX</span>メニューおよび利用方法等については、事業者（またはその受託者）が単独または<span>WELBOX</span>サプライヤーとの間で取り決めることを予め承諾するものとします。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、事業者の定める<span>WELBOX</span>メニューの利用方法に従って利用した場合に限り、会員が<span>WELBOX</span>メニューの特典等の適用を受けられるものであることを予め承諾するものとします。</span></p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、事業者が<span>WELBOX</span>メニューを随時改変する場合があることを予め承諾するものとします。</span></p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>WEL</span><span>コインの利用に関しては、「<span>WEL</span>コイン利用規約」に従うものとします。</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第４条（規約の遵守等）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、会員が本サービスを利用するにあたっては、本規約および事業者が提携している第三者が定めた規約等（以下併せて「規約等」といいます）を、会員に対し適用することを承諾するものとします。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービスの申し込みに先立ち、契約企業に所属する者に対し、本サービスの内容および前項に定める規約等について、周知徹底の上理解および遵守させる義務を負うものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第５条（本サービスの利用）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本サービスの利用に際しては、中部電力ミライズ株式会社（以下「ミライズ」といいます）の提供するビジエネ会員への登録が必要となります。また、契約企業は、本サービスの利用のために、事業者がミライズから、契約企業にかかる電力需給契約の有無、ガス需給契約の有無、および「ビジエネ」サービスにおける「料金・使用量照会サービス」（以下「照会サービス」といいます）の登録有無に関する情報を取得すること、及び契約企業の本サービスのご利用に関する情報をミライズ、三菱商事株式会社、中電エナジーサービス株式会社および株式会社<span>CD</span>エナジーダイレクト（以下「<span>CD</span>エナジー」といいます）に共有することについて予め承諾するものとします。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本規約に同意すると共に、事業者が指定するサービス申込<span>WEB</span>画面に従い必要事項を記載の上、事業者に対し本サービスの申し込みを頂く必要があります。</span></p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本サービスの申し込みは、企業情報および決済情報（クレジットカード情報）の登録を頂いた後、従業員情報の登録を頂くことで完了となります。従業員情報の登録がなされていない場合、または申し込み情報に不備がある場合、申し込みは未完了となります。契約企業は会員に対して事業者への情報提供について同意させるものとします。</span>
                    </p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>申し込み完了後、事業者より本サービスの利用に関する情報（会員<span>ID</span>、初期パスワード、サービスサイト等）の確認方法についてメール案内がありますので、それに従い、会員に関連情報を周知頂き、本サービスをご利用ください。本サービスの利用に関する情報は、会員本人のみが使用できるものとし、それ以外の第三者は使用できません。</span>
                    </p>

                    <p><span>5.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前月<span>11</span>日～当月<span>10</span>日までの申し込み分について、本条に従った手続きを踏まえ、会員には翌月<span>1</span>日より本サービスをご利用頂けます。（毎月<span>10</span>日を以て翌月からの利用申し込みを締め切らせて頂きます）。</span></p>

                    <p><span>6.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本条第２項に関し、申し込みをされた企業を、事業者にて確認後、本サービスのご利用をお断りさせて頂く可能性もございます。</span>
                    </p>

                    <p><span>7.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本サービスの契約期間は、申し込み完了した日から、当該日が属する年度（<span>4</span>月<span>1</span>日から翌年の<span>3</span>月<span>31</span>日までの期間をいいます）の末日までとします。また、契約期間満了の<span>3</span>か月前までに契約企業と事業者の双方が申入れを行わない場合は、本サービスは契約間満了後も<span>1</span>年ごとに同一条件で継続されるものとします。</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第６条（料金プラン）</span></p>

                    <p><span>本サービスには、以下２種類の料金プランがあります。契約企業は第５条第２項に従った本サービスの申込時に、料金プランを確認するものとします。ビジエネコースは、本サービス利用月の前月<span>10</span>日時点でミライズまたは<span>CD</span>エナジーとの電力需給契約またはガス需給契約を有し、かつ照会サービスにお申し込み頂いている契約企業に適用されます。</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>ビジエネコース</span></p>

                    <p><span>1</span><span>）初期費用ゼロ</span></p>

                    <p><span>2</span><span>）会員１人あたり料金<span>490</span>円<span>/</span>月（税抜）</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>ビジエネライトコース</span></p>

                    <p><span>1</span><span>）初期費用ゼロ</span></p>

                    <p><span>2</span><span>）会員１人あたり料金<span>700</span>円<span>/</span>月（税抜）</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第７条（<span>NETSEA</span>プライムの利用）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、株式会社<span>SynaBiz</span>が提供するインターネット上の仕入れサイト「<span>NETSEA</span>」プライムを無料で利用することができます。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>無料の<span>NETSEA</span>プライムアカウントは、１契約企業につき１アカウントとなります。</span></p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>無料の<span>NETSEA</span>プライムアカウントは、本サービスサイト内に設定されているリンクより登録頂くことで取得できます。当該リンクが設定される会員は１契約企業につき１人となります。当該会員は第５条第３項に従った従業員情報の登録時にご指定下さい。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第８条（アカウントの管理）</span></p>

                    <p><span>本サービスにかかるシステムのログイン<span>ID</span>、パスワード等については、契約企業の責任において管理者、会員に徹底管理させるものとします。</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第９条（サービス申込内容の変更（従業員の変更等））</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>第５条に従いご利用開始した後、登録した従業員情報に変更があった場合、サービス申込<span>WEB</span>画面より、当該変更を反映した従業員情報をアップロードの上、情報更新手続きをお願いします。毎月<span>10</span>日までに情報更新手続きを頂いた場合、翌月<span>1</span>日より当該更新後の情報に基づきご利用頂けます。当該従業員情報に不備があった場合、情報更新手続きは未完了となり、本サービスの利用ができないこともあります。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>従業員が追加で登録となった場合、事業者より当該会員の本サービスにかかる利用に関する情報（会員<span>ID</span>、初期パスワード等）のメール案内がありますので、それに従い、当該会員に関連情報を周知頂くことで、本サービスをご利用可能となります。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業の申込・契約情報に変更があった場合は、毎月<span>10</span>日までの変更内容が翌月利用分の請求情報に反映されます。料金プランに変更を与える情報（照会サービスの登録有無等）に変更があった場合は、毎月<span>10</span>日時点での登録情報が、翌月利用分の請求情報に反映されます。</span></p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１０条（サービス利用料金、支払方法等）　</span></p>

                    <p><span>本サービスのご利用月は以下の通り支払いが発生致します。</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、第５条３項に従い、本サービス申込時に決済情報（クレジットカード情報）を登録するものとします。クレジットカードを保有していない場合、本サービスをご利用頂くことはできません。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービス利用の対価として、サービス利用料金を以下の定めに従い事業者に支払うものとします。</span>
                    </p>

                    <p><span>a)<span>&nbsp;&nbsp;&nbsp; </span></span><span>サービス利用料金（会員１人あたり月額料金 × ご利用月の会員数）</span>
                    </p>

                    <p><span>会員１人あたりの月額料金は、第６条に定める料金とします。</span></p>

                    <p><span>ご利用月の会員数は、第５条、第９条２項に定めた方法に従い、契約企業がサービス申込<span>WEB</span>画面に登録した従業員数とします（ご利用月の会員数は、前月の<span>10</span>日時点で契約企業が登録した従業員数とします）。</span></p>

                    <p><span>b)<span>&nbsp;&nbsp;&nbsp; </span></span><span>決済</span></p>

                    <p><span>前項a)に従い算出したサービス利用料金は、ご利用月の前月の<span>25</span>日を以て支払い義務が発生し、その後各クレジットカード会社にて、決済処理が行われるものとします。</span></p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本条２項b)に従い決済に失敗した場合、その旨を事業者が契約企業にメールにて通知します。契約企業は、前月末までに、決済可能なクレジットカード情報に変更の上、事業者は再度決済処理を行うものとします。</span>
                    </p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前項に従い再度決済処理に失敗した場合、その旨を事業者が契約企業にメールにて通知します。契約企業は、同通知に記載された事業者の銀行口座にサービス利用料金を振り込むものとします。当振込に際し発生する振込手数料は契約企業が負担するものとします。</span>
                    </p>

                    <p><span>5.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前項に従った契約企業によるサービス利用料金の振り込みが、当月<span>11</span>日までに実施されなかった場合、事業者は速やかに契約企業の本サービスの利用停止手続きを実施します。</span></p>

                    <p><span>6.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前項に従い本サービスの利用停止がされたことにより契約企業または会員に損害が生じた場合であっても、事業者は当該損害を一切賠償する責任を負わないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１１条（禁止事項）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>１会員あたり１会員<span>ID</span>が付与されるものとし、１会員<span>ID</span>を複数名で利用することは出来ないものとします。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービスにかかる権利を、第三者に譲渡・承継・貸与、担保提供することはできないものとします。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービスにかかる権利を、サブライセンス、リース、レンタル、ローン、販売することはできないものとします。</span>
                    </p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービスにかかる製品やソフトウェア等を複写、改造、カスタマイズ、リバースエンジニアリング、逆コンパイル、逆アセンブルをすることはできないものとします。</span>
                    </p>

                    <p><span>5.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業は、本サービスに関して他人に迷惑を及ぼす行為､公序良俗に反する利用、または本サービスの秩序を乱す行為をしてはならないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１２条（委託）</span></p>

                    <p><span>事業者は、事業者の責任において、本サービスの一部を必要な範囲で株式会社イーウェルを含む第三者に委託することができます。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１３条（賠償責任）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は、本サービスに関し、自己の責めに帰すべき事由により、相手方に損害を発生させた場合、直接かつ現実に生じた通常の損害に限り賠償するものとし、過去<span>1</span>年以内に当該契約企業から支払われたサービス利用料金を上限とします。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前項にかかわらず、本サービスに関する債務が、天災地変、<span>SARS</span>・鳥インフルエンザ等の感染症・伝染病、戦争、内乱、テロ、ストライキ、ロックアウト、サプライヤー等契約外第三者の債務不履行、輸送手段の利用困難、事務所等の火災、経済情勢の著しい変動、電力供給の逼迫、通信回線の事故、法令の改廃・制定、その他の不可抗力により遅滞または不能となるときは、その事由が継続する期間に限り、債務者はその責任を負わないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１４条（免責）</span></p>

                    <p><span>契約企業は、本サービスの提供に際し、事業者の責任範囲につき、次の各号につき予め承諾するものとします。</span>
                    </p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業が第４条２項を遵守せず、契約企業が本サービスに関する契約の申込みに先立ち契約企業に属する者に対し、本サービスの内容および規約等について周知徹底の上理解および遵守させる義務を怠った結果、契約企業、会員に生じた不利益について、事業者は責任を負わないものとします。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>第５条４項、および第９条２項に従い事業者が通知した会員のサービス利用に関する情報（会員<span>ID</span>、初期パスワード等）を、契約企業が正しく会員に周知しなかった結果、契約企業および会員に生じた不利益について、事業者は責任を負わないものとします。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業が事業者に指定または提供した情報に誤りがあった結果、本サービスを通じ契約企業、会員に生じた不利益について、事業者は責任を負わないものとします。</span>
                    </p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者は、本サービスにおいて契約企業または会員によって提供される情報（以下「提供情報」といいます）の正確性および最新性を保つために商業的に合理的な努力を行うが、その完全な正確性および最新性について、責任を負わないものとします。</span>
                    </p>

                    <p><span>5.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業または会員における提供情報の採否は、契約企業または会員の自己責任で決定されるべきものとし、契約企業または会員がかかる提供情報に依拠したことによって契約企業または会員に発生した損害について、事業者は責任を負わないものとします。</span>
                    </p>

                    <p><span>6.<span>&nbsp;&nbsp;&nbsp; </span></span><span>その他、メール受信拒否設定等により事業者からの通知が到達しないこと、本サービスを利用するにあたり使用するハードウェアおよびソフトウェアの故障、不備、使用方法の誤り等、その他の契約企業または会員の責めに帰すべき事由により当該契約企業または当該会員に損害が発生しても事業者は責任を負わないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１６条（システム停止時の責任）</span>
                    </p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者が提供する本サービスに関するシステムが、システム保守や障害対応等により、利用できない状態となった場合であっても、事業者は契約企業および会員に対し一切の責任を負わないものとします。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者が前項に基づき本サービスに関するシステムの停止を行う場合、原則として事前に周知を行うものとし、事前周知が不可能な場合には、速やかに事後周知を行うものとします。　</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１７条（本規約の変更）</span></p>

                    <p><span>本規約は、契約企業の事前の承諾を得ることなく変更される場合があります。この場合、変更後の本規約内容をメール等にて共有し、効力発生日までに契約企業が何らの異議も述べない場合には、契約企業は、変更後の本規約に同意したものとみなします。ただし、変更が軽微で契約企業に特に不利益にならないと事業者が判断した場合、同対応は実施しないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１８条（解約）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本サービスは、契約企業が解約の申込を行わない限り継続するものとし、契約企業は、本サービスの解約を希望する場合、サービス申込<span>WEB</span>画面から解約手続きを行うものとします。</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者の都合により本サービスの提供が著しく困難または不可能となった場合、事業者は契約企業に対し２ヵ月の予告期間を経過した後、本サービスの提供または履行を終了することができるものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第１９条（利用停止）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者は、契約企業または会員が次の各号のいずれかに該当する事由が生じた場合は、何らの事前の通知・催告その他の手続を要しないで、本サービスの利用を一時的に停止し、または、直ちに本サービスの全部または一部を解除することができるものとします。</span>
                    </p>

                    <p><span>a)<span>&nbsp;&nbsp;&nbsp; </span></span><span>本規約のいずれかの条項に違反したとき</span></p>

                    <p><span>b)<span>&nbsp;&nbsp;&nbsp; </span></span><span>登録事項に虚偽があることが判明したとき</span></p>

                    <p><span>c)<span>&nbsp;&nbsp;&nbsp; </span></span><span>破産、民事再生または会社更生手続開始の申し立てがあったとき</span></p>

                    <p><span>d)<span>&nbsp;&nbsp;&nbsp; </span></span><span>支払停止もしくは支払不能に陥ったとき、または手形交換所から警告もしくは不渡処分を受けたとき</span>
                    </p>

                    <p><span>e)<span>&nbsp;&nbsp;&nbsp; </span></span><span>第三者より差押、仮差押、仮処分、強制執行もしくは競売の申立て、または公租公課の滞納処分をうけたとき</span>
                    </p>

                    <p><span>f)<span>&nbsp;&nbsp;&nbsp; </span></span><span>信用・財産・経営状態の著しい悪化またはそのおそれがあると認められる相当の理由があるとき</span>
                    </p>

                    <p><span>g)<span>&nbsp;&nbsp;&nbsp; </span></span><span>所在不明等連絡が著しく困難なとき</span></p>

                    <p><span>h)<span>&nbsp;&nbsp;&nbsp; </span></span><span>その他、事業主が本サービスの利用を適当でないと判断したとき</span></p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>前各号のいずれかの事由に該当した場合、契約企業は、事業主に対して負っている債務の一切について当然に期限の利益を失い、直ちに事業主に対して全ての債務の支払いを行わなければなりません。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業主は、本条に基づき事業主が行った行為により契約企業または会員に生じた損害について一切の責任を負いません。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２０条（サービスの中止・中断、一時停止）</span></p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者は、直ちに本サービスの提供を停止しなければ、事業者、契約企業、会員または第三者に著しい不利益が及ぶおそれがあるときは、何らの事前通知なく、直ちに、本サービスの提供を停止することができるものとします。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者は、前項に基づき本サービスの提供を停止した場合には、契約企業に速やかに連絡します。ただし、契約企業の所在不明等特段の事情がある場合には、この限りではないものとします。</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>事業者は、本条第１項に基づく本サービスの提供の停止につき、一切責任を負わないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２１条（解約等に伴う特約）</span></p>

                    <p><span>本サービスが解約等により終了した場合、契約企業は、解約日までに本サービスを通して会員が申し込みした各種<span>WELBOX</span>メニュー等の履行が完了しないものについては、原則としてキャンセル扱いになるものとし、事業者はその履行責任を負わないことを、予め承諾するものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２２条（機密の保持）</span></p>

                    <p><span>契約企業および事業者は、本契約存続中はもちろんのこと契約終了後といえども、本契約により知り得た相手方の機密を他に漏らしてはならないものとします。　</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２３条（個人情報のお取り扱い）</span>
                    </p>

                    <p><span>本サービスの利用において、契約企業および会員の個人情報の取り扱いについては、事業者がホームページ上で公表する「個人情報のお取扱いについて」（<span>https://miraiz-connect.co.jp/privacy/</span>）に従うものとします。</span></p>

                    <p><span>契約企業はこれらに同意するとともに、会員に対してこれらに同意させるものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２４条（反社会的勢力の排除）　</span>
                    </p>

                    <p><span>1.<span>&nbsp;&nbsp;&nbsp; </span></span><span>本条において「反社会的勢力」とは、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他これらに準ずる者をいうものとします。</span>
                    </p>

                    <p><span>2.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は、現時点および将来にわたって、自己が次の各号のいずれにも該当しないことを表明し確約するものとします。</span>
                    </p>

                    <p><span>a)<span>&nbsp;&nbsp;&nbsp; </span></span><span>反社会的勢力であることまたは反社会的勢力であったこと</span></p>

                    <p><span>b)<span>&nbsp;&nbsp;&nbsp; </span></span><span>反社会的勢力が経営を支配していること</span></p>

                    <p><span>c)<span>&nbsp;&nbsp;&nbsp; </span></span><span>代表者、責任者または経営に実質的に関与している者が反社会的勢力であること</span>
                    </p>

                    <p><span>d)<span>&nbsp;&nbsp;&nbsp; </span></span><span>暴力的ないし威迫的な犯罪行為を行ったとして公に認識され、もしくは報道その他により一般に認識されることまたはこの者とかかわり、つながりを持つこと</span>
                    </p>

                    <p><span>3.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は、反社会的勢力と次の各号のいずれの関係も有しておらず、将来も持たないことを表明し確約するものとします。</span>
                    </p>

                    <p><span>a)<span>&nbsp;&nbsp;&nbsp; </span></span><span>自己または第三者の不正の利益を図る目的をもってするなど、不当に反社会的勢力を利用する関係</span>
                    </p>

                    <p><span>b)<span>&nbsp;&nbsp;&nbsp; </span></span><span>反社会的勢力に対して資金等を提供し、または便宜の供与をするなど、反社会的勢力に関与する関係</span>
                    </p>

                    <p><span>c)<span>&nbsp;&nbsp;&nbsp; </span></span><span>その他社会的に非難されるべき関係</span></p>

                    <p><span>4.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は相手方に対して、次の各号のいずれの行為も、自らまたは第三者を利用して行わないことを確約するものとします。</span>
                    </p>

                    <p><span>a)<span>&nbsp;&nbsp;&nbsp; </span></span><span>暴力的な要求行為</span></p>

                    <p><span>b)<span>&nbsp;&nbsp;&nbsp; </span></span><span>法的な責任を超えた不当な要求行為</span></p>

                    <p><span>c)<span>&nbsp;&nbsp;&nbsp; </span></span><span>取引に関して脅迫的な言動をし、または暴力を用いる行為</span></p>

                    <p><span>d)<span>&nbsp;&nbsp;&nbsp; </span></span><span>風説を流布し、または偽計もしくは威力を用い、相手方の信用を毀損し、または相手方の業務を妨害する行為</span>
                    </p>

                    <p><span>e)<span>&nbsp;&nbsp;&nbsp; </span></span><span>その他前各号に準ずる行為</span></p>

                    <p><span>5.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は、自己が本契約の遂行のために用いる者（個人か企業かを問わず、数次の取引先など第三者を介して用いる者を含み、以下「履行補助者」といいます）が、第２項各号のいずれかに該当し、第３項各号のいずれかの関係を持ち、または前項各号のいずれかの行為を行ったときには、ただちに当該履行補助者との契約を解除し、または契約解除のための措置を採ることを確約するものとします。</span>
                    </p>

                    <p><span>6.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業および事業者は、自己または履行補助者が、本契約の遂行に関連して、反社会的勢力から不当要求または業務妨害等の不当介入を受けた場合には、これを拒否し、または履行補助者をして拒否させるとともに、すみやかに当該事実を相手方に報告し、相手方の捜査機関への通報に必要な協力を行うことを確約するものとします。</span>
                    </p>

                    <p><span>7.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業または事業者が本条の表明または確約のいずれかに反した場合には、相手方は何らの催告を要しないで、本契約を解除することができるものとします。</span>
                    </p>

                    <p><span>8.<span>&nbsp;&nbsp;&nbsp; </span></span><span>契約企業または事業者が前項の規定により本契約を解除した場合には、相手方に損害が生じても、解除した者はこれを賠償することを一切要せず、また、当該解除により解除した者に損害が生じたときは、相手方はその損害を賠償するものとします。　</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２５条（譲渡禁止）</span></p>

                    <p><span>契約企業および事業者は、相手方の書面による事前の承諾を得た場合を除き、本契約から生じる地位、権利および義務の全部または一部を第三者に譲渡し、または担保に供してはならないものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２６条（準拠法および管轄裁判所）</span>
                    </p>

                    <p><span>本規約の準拠法は日本法とし、本規約または本サービスに関する一切の紛争については、名古屋簡易裁判所または名古屋地方裁判所をもって第一審の専属的管轄裁判所とします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>第２７条（協議事項）</span></p>

                    <p><span>本規約に定めのない事項について疑義が生じた場合には、契約企業および事業者は誠意をもって協議のうえ解決するものとします。</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <p><span>&nbsp;</span>
                    </p>

                    <Typography align={"right"} paddingRight={"4"}><span>中部電力ミライズコネクト株式会社</span></Typography>

                    <p><span>&nbsp;</span>
                    </p>
                    </Box>
                    <Typography align={"right"} paddingRight={"4"}><span>2022</span><span>年<span>4</span>月<span>1</span>日更新</span></Typography>
                </Paper>
            </Box>
            <Box width={"3xl"} paddingTop={2} paddingBottom={2}>
                <Typography>上記のご利用規約をご確認のうえ、「次へ」ボタンをお願いします。</Typography>
            </Box>
        </Container>
    )
}