import { Box, Button, Typography } from "@mui/material"

export const ThankyouForRequest = () => {
    return (
        <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
            <Box display={"grid"}>
                <Typography>
                    この度はコネクトWELBOXへお申し込みいただき、誠にありがとうございました。
                </Typography>
                <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
                    <Button href="https://bizene.chuden.jp/member/p/marche/bpo/">コネクトWELBOXのページへ戻る</Button>
                </Box>
            </Box>
        </Box>
    )
}
